import React from "react"
import { Route, Switch } from "react-router-dom"
import TecResultFact from "./containers/TecResultFact"
import TecResultPlan from "./containers/TecResultPlan"
import FinResultFact from "./containers/FinResultFact"
import FinResultPlan from "./containers/FinResultPlan"
import PlanFact from "./containers/PlanFact"

const BudgetBudgetFots = () => (
  <Switch>
    <Route path="/budgets/:budget_id/company_summary/tec_result_plan" component={TecResultPlan} />
    <Route path="/budgets/:budget_id/company_summary/tec_result_fact" component={TecResultFact} />
    <Route path="/budgets/:budget_id/company_summary/fin_result_plan" component={FinResultPlan} />
    <Route path="/budgets/:budget_id/company_summary/fin_result_fact" component={FinResultFact} />
    <Route path="/budgets/:budget_id/company_summary/plan_fact" component={PlanFact} />
  </Switch>
)

export default BudgetBudgetFots