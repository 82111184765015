import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import BudgetIncomesStore from "stores/BudgetIncomeStore/One"
import Form from "pages/Budgets/containers/BudgetIncomes/components/Form"
import { BreadcrumbItem } from "components/UI"

const budgetStore = BudgetStore.create()
const budgetIncomeStore = BudgetIncomesStore.create()

const Add = (props) => {
  const { budget_id } = props.match.params

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetIncomeStore.fetchMeta({ budget_id: budget_id })
  }, [])

  budgetIncomeStore.reset()

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem url={`/budgets/${budget_id}/budget_incomes`}>Прочие доходы</BreadcrumbItem>
        <BreadcrumbItem active>Создать</BreadcrumbItem>
      </Breadcrumbs>

      <Form {...{ store: budgetIncomeStore, budgetStore }} />
    </Fragment>
  )
}

export default observer(Add)
