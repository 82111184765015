import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import format from "date-fns/format"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import customDate from "types/customDate"
// import { Position } from "./Position"

const Filter = types.model("Filter", {
  by_subdivision: types.maybeNull(customEnum),
  by_slice_kind: types.frozen(),
  by_period_from: types.maybeNull(customDate),
  by_period_to: types.maybeNull(customDate)
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  search_id: types.maybeNull(types.number),
  statistic: types.frozen(),
  colors: types.frozen()
})

const metaDefaultValue = {
  filter: {
    by_subdivision: { id: null, name: null },
    by_slice_kind: "1"
  },
  colors: [],
  statistic: {
    "КВ по опл. СП": {},
    "СП оплаченная": {}
  }
}

const ReportStore = types
  .model("ReportStore", {
    data: types.optional(types.frozen(), []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { properties, ...other } = params.filter

        let filter = {}

        filter = {
          ...filter,
          ...other,
          properties: properties.map((property, index) => {
            const {
              by_period_from,
              by_period_to,
              by_subdivision,
              by_maintenance_user,
              by_maintenance_subdivision,
              by_insurer,
              by_channel,
              by_client,
              by_number,
              by_user,
              by_insurance_kind,
              by_subagent,
              by_tag,
              by_holding,
              ...other
            } = property

            let filter = {}
            if (by_period_from) {
              filter = { ...filter, ...other, by_period_from: format(by_period_from, "yyyy-MM-dd") }
            }
            if (by_period_to) {
              filter = { ...filter, ...other, by_period_to: format(by_period_to, "yyyy-MM-dd") }
            }
            if (by_subdivision) {
              filter = { ...filter, ...other, by_subdivision_ids: (by_subdivision.id || []).map((i) => i.value) }
            }
            if (by_maintenance_user) {
              filter = { ...filter, ...other, by_maintenance_user_ids: (by_maintenance_user.id || []).map((i) => i.value) }
            }
            if (by_maintenance_subdivision) {
              filter = { ...filter, ...other, by_maintenance_subdivision_ids: (by_maintenance_subdivision.id || []).map((i) => i.value) }
            }
            if (by_insurer) {
              filter = { ...filter, ...other, by_insurer_ids: (by_insurer.id || []).map((i) => i.value) }
            }
            if (by_channel) {
              filter = { ...filter, ...other, by_channel_ids: (by_channel.id || []).map((i) => i.value) }
            }
            if (by_client) {
              filter = { ...filter, ...other, by_client_ids: (by_client.id || []).map((i) => i.value) }
            }
            if (by_number) {
              filter = { ...filter, ...other, by_number: by_number }
            }
            if (by_user) {
              filter = { ...filter, ...other, by_user_ids: (by_user.id || []).map((i) => i.value) }
            }
            if (by_insurance_kind) {
              filter = { ...filter, ...other, by_insurance_kind_ids: (by_insurance_kind.id || []).map((i) => i.value) }
            }
            if (by_subagent) {
              filter = { ...filter, ...other, by_subagent_ids: (by_subagent.id || []).map((i) => i.value) }
            }
            if (by_tag) {
              filter = { ...filter, ...other, by_tag_ids: (by_tag || []).map((i) => i.value) }
            }
            if (by_holding) {
              filter = { ...filter, ...other, by_holding_ids: (by_holding.id || []).map((i) => i.value) }
            }

            return { [`properties_${index}`]: filter }
          })
        }

        params.filter = filter
      }

      // console.log(params)

      return instance
        .get("/reports/new_sales", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      // if (status === 200) applySnapshot(self, data)
      if (status === 200)
        applySnapshot(self, {
          data: data.data,
          meta: { ...self.meta, statistic: data.meta.statistic, colors: data.meta.colors }
        })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ReportStore
