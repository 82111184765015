import React from "react"
import compose from "utils/compose"
import { DateInput, ResetButton, SelectInput, Submit, TextInput } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import ClientSelect from "components/Common/ClientSelect"
import InsuranceKindSelect from "components/Common/InsuranceKindSelect"
import UserSelect from "components/Common/UserSelect"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import DocumentKindSelect from "components/Common/DocumentKindSelect"

const Filter = ({ store, values, handleSubmit }) => {
  const { statuses, kinds, submissions } = store.filtersOptions
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <InsurerSelect name="by_insurer" />
        </div>
        <div className="col-3">
          <ClientSelect name="by_client" />
        </div>
        <div className="col-3">
          <InsuranceKindSelect name="by_insurance_kind" />
        </div>
        <div className="col-3">
          <SelectInput name="by_status" label="Статус" options={statuses} />
        </div>
        <div className="col-3">
          <SubdivisionSelect name="by_subdivision.id" />
        </div>
        <div className="col-3">
          <DateInput name="by_conclusion_on_from" label="Дата договора с" />
        </div>
        <div className="col-3">
          <DateInput name="by_conclusion_on_to" label="Дата договора по" />
        </div>
        <div className="col-3">
          <TextInput name="by_number" label="№ договора" />
        </div>
        <div className="col-3">
          <SelectInput name="by_kind" label="Вид документа" options={kinds} />
        </div>
        <div className="col-3">
          <SelectInput name="by_submission" label="Геолокация документа" options={submissions} />
        </div>
        <div className="col-3">
          <DocumentKindSelect name="by_document_kind.id" ids={[4, 5, 6, 19, 18]} />
        </div>
        <div className="col-3">
          <TextInput name="by_submission_holder" label="Кому сдан документ" />
        </div>
        <div className="col-3">
          <UserSelect name="by_maintenance_user.id" label="Сотрудник сопровождения" />
        </div>
        {/* <div className="col-3">
          <ChannelSelect name="by_channel" />
        </div>
        <div className="col-3">
          <TextInput name="by_number" label="Номер БСО" />
        </div>
        <div className="col-3">
          <SelectInput name="by_form_status" label="Статус БСО" options={form_statuses} />
        </div> */}
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  })
)(Filter)
