import React from "react"
import AuthStoreContext from "contexts/AuthStoreContext"
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom"
import Appeals from "./Appeals"
import ActivityKinds from "./ActivityKinds"
import AgencyContracts from "./AgencyContracts"
import Auth from "./Auth"
import AuthStore from "stores/AuthStore"
import Avrs from "./Avrs"
import Bills from "./Bills"
import BsoKinds from "./BsoKinds"
import Bsos from "./Bsos"
import Budgets from "./Budgets"
import CarBrands from "./CarBrands"
import CarModels from "./CarModels"
import Channels from "./Channels"
import Clients from "./Clients"
import ContractDocuments from "./ContractDocuments"
import ContractTasks from "./ContractTasks"
import Contracts from "./Contracts"
import Couriers from "./Couriers"
import Courts from "./Courts"
import Dashboard from "./Dashboard"
import DebitKpis from "./DebitKpis"
import Debits from "./Debits"
import LegalDebits from "./LegalDebits"
import Destinations from "./Destinations"
import DocumentKinds from "./DocumentKinds"
import Holdings from "./Holdings"
import InboxDocumentFlows from "./InboxDocumentFlows"
import Industries from "./Industries"
import InsuranceKinds from "./InsuranceKinds"
import Insurers from "./Insurers"
import KvCosts from "./KvCosts"
import LegalKvCosts from "./LegalKvCosts"
import Losses from "./Losses"
import NotFound from "./NotFound"
import Operations from "./Operations"
import OrderSubjects from "./OrderSubjects"
import Orders from "./Orders"
import PaymentPlans from "./PaymentPlans"
import Placements from "./Placements"
import Positions from "./Positions"
import Projects from "./Projects"
import Providers from "./Providers"
import Reports from "./Reports"
import Risks from "./Risks"
import Routes from "./Routes"
import SalePlans from "./SalePlans"
import SentDocumentFlows from "./SentDocumentFlows"
import Shoutdown from "./Shoutdown"
import SideBar from "./shared/components/SideBar"
import Signers from "./Signers"
import Sources from "./Sources"
import Strengths from "./Strengths"
import Subagents from "./Subagents"
import Subdivisions from "./Subdivisions"
import TopBar from "./shared/components/TopBar"
import Users from "./Users"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const authStore = AuthStore.create()

const App = () => {
  if (authStore.isAuthenticated) {
    return (
      <AuthStoreContext.Provider value={authStore}>
        <Router>
          <div id="wrapper">
            <SideBar />
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <TopBar />
                <div className="container-fluid">
                  <ToastContainer />
                  <Switch>
                    <Route path="/appeals" component={Appeals} />
                    <Route path="/activity_kinds" component={ActivityKinds} />
                    <Route path="/agency_contracts" component={AgencyContracts} />
                    <Route path="/avrs" component={Avrs} />
                    <Route path="/bills" component={Bills} />
                    <Route path="/bso_kinds" component={BsoKinds} />
                    <Route path="/bsos" component={Bsos} />
                    <Route path="/budgets" component={Budgets} />
                    <Route path="/car_brands" component={CarBrands} />
                    <Route path="/car_models" component={CarModels} />
                    <Route path="/channels" component={Channels} />
                    <Route path="/clients" component={Clients} />
                    <Route path="/contract_documents" component={ContractDocuments} />
                    <Route path="/contract_tasks" component={ContractTasks} />
                    <Route path="/contracts" component={Contracts} />
                    <Route path="/couriers" component={Couriers} />
                    <Route path="/courts" component={Courts} />
                    <Route path="/debit_kpis" component={DebitKpis} />
                    <Route path="/debits" component={Debits} />
                    <Route path="/legal_debits" component={LegalDebits} />
                    <Route path="/destinations" component={Destinations} />
                    <Route path="/document_kinds" component={DocumentKinds} />
                    <Route path="/holdings" component={Holdings} />
                    <Route path="/inbox_document_flows" component={InboxDocumentFlows} />
                    <Route path="/industries" component={Industries} />
                    <Route path="/insurance_kinds" component={InsuranceKinds} />
                    <Route path="/insurers" component={Insurers} />
                    <Route path="/kv_costs" component={KvCosts} />
                    <Route path="/legal_kv_costs" component={LegalKvCosts} />
                    <Route path="/losses" component={Losses} />
                    <Route path="/operations" component={Operations} />
                    <Route path="/order_subjects" component={OrderSubjects} />
                    <Route path="/orders" component={Orders} />
                    <Route path="/payment_plans" component={PaymentPlans} />
                    <Route path="/placements" component={Placements} />
                    <Route path="/positions" component={Positions} />
                    <Route path="/projects" component={Projects} />
                    <Route path="/providers" component={Providers} />
                    <Route path="/reports" component={Reports} />
                    <Route path="/risks" component={Risks} />
                    <Route path="/routes" component={Routes} />
                    <Route path="/sale_plans" component={SalePlans} />
                    <Route path="/sent_document_flows" component={SentDocumentFlows} />
                    <Route path="/shoutdown" component={Shoutdown} />
                    <Route path="/signers" component={Signers} />
                    <Route path="/sources" component={Sources} />
                    <Route path="/strengths" component={Strengths} />
                    <Route path="/subagents" component={Subagents} />
                    <Route path="/subdivisions" component={Subdivisions} />
                    <Route path="/users" component={Users} />
                    <Route path="/" exact component={Dashboard} />
                    <Route path="*" exact component={NotFound} />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </Router>
      </AuthStoreContext.Provider>
    )
  } else {
    return (
      <Router>
        <Route path="/auth" component={Auth} />
        <Redirect exact to="/auth/login" />
      </Router>
    )
  }
}

export default App
