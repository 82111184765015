import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Plan from "./containers/Plan"

const BudgetBudgetIncomes = () => (
  <Switch>
    <Route path="/budgets/:budget_id/budget_incomes/plan" component={Plan} />
    <Route path="/budgets/:budget_id/budget_incomes/:id/edit" component={Edit} />
    <Route path="/budgets/:budget_id/budget_incomes/new" component={Add} />
    <Route path="/budgets/:budget_id/budget_incomes/:id" component={Show} />
    <Route path="/budgets/:budget_id/budget_incomes" component={List} />
  </Switch>
)

export default BudgetBudgetIncomes