import { types } from "mobx-state-tree"
import newCustomEnum from "types/newCustomEnum"
import customDate from "types/customDate"

const User = types.model("User", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const Subdivision = types.model("Subdivision", {
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
})

const BudgetMotivation = types.model("BudgetMotivation", {
  id: types.maybeNull(types.integer),
  budget_id: types.maybeNull(types.integer),
  kind: types.maybeNull(newCustomEnum),
  user_sale_plan_kind: types.maybeNull(newCustomEnum),
  rate_kind: types.maybeNull(newCustomEnum),
  status: types.maybeNull(newCustomEnum),
  user: types.maybeNull(User),
  user_sale_plan: types.maybeNull(User),
  start_on: types.maybeNull(customDate),
  finish_on: types.maybeNull(customDate),
  periodicity: types.maybeNull(newCustomEnum),
  basis: types.maybeNull(newCustomEnum),
  condition: types.maybeNull(newCustomEnum),
  comment: types.maybeNull(types.string),
  rate: types.maybeNull(types.string),
  amount: types.maybeNull(types.string),

  quarter_1_amount: types.maybeNull(types.string),
  quarter_2_amount: types.maybeNull(types.string),
  quarter_3_amount: types.maybeNull(types.string),
  quarter_4_amount: types.maybeNull(types.string),

  month_1_amount: types.maybeNull(types.string),
  month_2_amount: types.maybeNull(types.string),
  month_3_amount: types.maybeNull(types.string),
  month_4_amount: types.maybeNull(types.string),
  month_5_amount: types.maybeNull(types.string),
  month_6_amount: types.maybeNull(types.string),
  month_7_amount: types.maybeNull(types.string),
  month_8_amount: types.maybeNull(types.string),
  month_9_amount: types.maybeNull(types.string),
  month_10_amount: types.maybeNull(types.string),
  month_11_amount: types.maybeNull(types.string),
  month_12_amount: types.maybeNull(types.string),

  is_use_coefficient: types.maybeNull(types.boolean),
  subdivision: types.maybeNull(Subdivision)
})

export { BudgetMotivation }
