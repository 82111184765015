import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetIncomesStore from "stores/BudgetIncomeStore/List"
import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import { Button, Card, BreadcrumbItem } from "components/UI"
import BudgetStore from "stores/BudgetStore/One"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/BudgetIncomes/components/Tabs"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"
import sortBy from "lodash.sortby"
import instance from "connection/instance"

const budgetStore = BudgetStore.create()
const budgetIncomeStore = BudgetIncomesStore.create()

const List = (props) => {
  const { budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetIncomeStore.fetchAll({ budget_id })
  }, [])

  const [fact, setFact] = useState([])
  useEffect(() => {
    instance.get(`/budgets/${budget_id}/budget_incomes/fact`).then(({ data }) => {
      setFact(data)
    })
  }, [])

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Прочие доходы</BreadcrumbItem>
        <BreadcrumbItem active>План-факт</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "4300px" }}>
          <thead>
            <tr>
              <th colSpan={19}>План Прочие доходы</th>
            </tr>
            <tr>
              <th rowSpan={3}>#</th>
              <th rowSpan={3}>Статья прочих доходов</th>
              <th rowSpan={2}>ИТОГО {budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>
                {formatMoney(
                  budgetIncomeStore.data.reduce(
                    (a, b) => a + b.budget_income_items.reduce((a2, b2) => a2 + parseMoney(b2.cost), 0),
                    0
                  )
                )}
              </th>
              <th>
                {formatMoney(
                  budgetIncomeStore.data.reduce(
                    (a, b) =>
                      a +
                      parseMoney(b.budget_income_items[0].cost) +
                      parseMoney(b.budget_income_items[1].cost) +
                      parseMoney(b.budget_income_items[2].cost),
                    0
                  )
                )}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[0].cost), 0))}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[1].cost), 0))}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[2].cost), 0))}
              </th>
              <th>
                {formatMoney(
                  budgetIncomeStore.data.reduce(
                    (a, b) =>
                      a +
                      parseMoney(b.budget_income_items[3].cost) +
                      parseMoney(b.budget_income_items[4].cost) +
                      parseMoney(b.budget_income_items[5].cost),
                    0
                  )
                )}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[3].cost), 0))}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[4].cost), 0))}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[5].cost), 0))}
              </th>
              <th>
                {formatMoney(
                  budgetIncomeStore.data.reduce(
                    (a, b) =>
                      a +
                      parseMoney(b.budget_income_items[6].cost) +
                      parseMoney(b.budget_income_items[7].cost) +
                      parseMoney(b.budget_income_items[8].cost),
                    0
                  )
                )}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[6].cost), 0))}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[7].cost), 0))}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[8].cost), 0))}
              </th>
              <th>
                {formatMoney(
                  budgetIncomeStore.data.reduce(
                    (a, b) =>
                      a +
                      parseMoney(b.budget_income_items[9].cost) +
                      parseMoney(b.budget_income_items[10].cost) +
                      parseMoney(b.budget_income_items[11].cost),
                    0
                  )
                )}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[9].cost), 0))}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[10].cost), 0))}
              </th>
              <th>
                {formatMoney(budgetIncomeStore.data.reduce((a, b) => a + parseMoney(b.budget_income_items[11].cost), 0))}
              </th>
            </tr>
          </thead>
          {budgetIncomeStore.data.length > 0 && (
            <tbody>
              {sortBy(budgetIncomeStore.data, (i) => i.bill.name).map((values) => (
                <tr>
                  <td>{values.bill.index}</td>
                  <td>{values.bill.name}</td>
                  <td>{formatMoney(values.budget_income_items.reduce((a, b) => a + parseMoney(b.cost), 0))}</td>
                  {values.budget_income_items.map((item, itemIndex) => (
                    <Fragment key={`item=${itemIndex}`}>
                      {itemIndex % 3 === 0 && (
                        <td style={{ verticalAlign: "middle", width: "200px" }}>
                          {formatMoney(
                            values.budget_income_items
                              .slice(itemIndex, itemIndex + 3)
                              .reduce((a, b) => a + parseMoney(b.cost), 0)
                          )}
                        </td>
                      )}
                      <td>{formatMoney(item.cost)}</td>
                    </Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
          <thead>
            <tr>
              <th colSpan={19}>Факт Прочие доходы</th>
            </tr>
            <tr>
              <th rowSpan={3}>#</th>
              <th rowSpan={3}>Статья прочих доходов</th>
              <th rowSpan={2}>ИТОГО {budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th colSpan={4}>План 4КВ{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>1КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ЯНВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ФЕВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАРТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>2КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АПР{budgetStore.data.year.toString().slice(-2)}</th>
              <th>МАЙ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮНЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>3КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ИЮЛЬ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>АВГ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>СЕН{budgetStore.data.year.toString().slice(-2)}</th>
              <th>4КВ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ОКТ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>НОЯ{budgetStore.data.year.toString().slice(-2)}</th>
              <th>ДЕК{budgetStore.data.year.toString().slice(-2)}</th>
            </tr>
            <tr>
              <th>
                {formatMoney(
                  fact.map((i) => i.costs.reduce((a, b) => a + parseMoney(b), 0)).reduce((a, b) => a + parseMoney(b), 0)
                )}
              </th>
              {Array(12)
                .fill(null)
                .map((_, index) => (
                  <Fragment key={`item=${index}`}>
                    {index % 3 === 0 && (
                      <th style={{ verticalAlign: "middle", width: "200px" }}>
                        {formatMoney(
                          fact
                            .map((i) => i.costs.slice(index, index + 3).reduce((a, b) => a + parseMoney(b), 0))
                            .reduce((a, b) => a + parseMoney(b), 0)
                        )}
                      </th>
                    )}
                    <th>{formatMoney(fact.map((i) => i.costs[index]).reduce((a, b) => a + parseMoney(b), 0))}</th>
                  </Fragment>
                ))}
            </tr>
          </thead>
          {fact && (
            <tbody>
              {fact.map((values) => (
                <tr>
                  <td>{values.id}</td>
                  <td>{values.name}</td>
                  <td>{formatMoney(values.costs.reduce((a, b) => a + parseMoney(b), 0))}</td>
                  {values.costs.map((cost, costIndex) => (
                    <Fragment key={`item=${costIndex}`}>
                      {costIndex % 3 === 0 && (
                        <td style={{ verticalAlign: "middle", width: "200px" }}>
                          {formatMoney(
                            values.costs.slice(costIndex, costIndex + 3).reduce((a, b) => a + parseMoney(b), 0)
                          )}
                        </td>
                      )}
                      <td>{formatMoney(cost)}</td>
                    </Fragment>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </Fragment>
  )
}

export default observer(List)
