import React from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { ResetButton, SelectInput, Submit, TextInput, DateInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import ClientSelect from "components/Common/ClientSelect"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import InsuranceKindSelect from "components/Common/InsuranceKindSelect"
import UserSelect from "components/Common/UserSelect"

const Filter = ({ store, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <InsurerSelect name="by_insurer" />
        </div>
        <div className="col-3">
          <InsuranceKindSelect name="by_insurance_kind.id" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_from" label="Дата убытка с" />
        </div>
        <div className="col-3">
          <DateInput name="by_date_on_to" label="Дата убытка по" />
        </div>
        <div className="col-3">
          <ClientSelect name="by_client.id" />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_result"
            label="Результат"
            options={[
              { label: "В работе", value: "in_work" },
              { label: "Отказ", value: "refuse" },
              { label: "Выплачено", value: "payout" }
            ]}
          />
        </div>
        <div className="col-3">
          <SelectInput
            name="by_status"
            label="Стадия"
            options={[
              { label: "Заявлен", value: "declared" },
              { label: "Сбор документов", value: "collect_document" },
              { label: "Рассмотрение убытка", value: "review" },
              { label: "Закрыт", value: "closed" }
            ]}
          />
        </div>
        <div className="col-3">
          <UserSelect name="by_maintenance_user.id" label="Сотрудник сопровождения" />
        </div>
      </div>
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  })
)(Filter)
