import React, { Fragment, useEffect, useState } from "react"
import compose from "utils/compose"
import { AsyncSelectInput, DateInput, TextInput, ResetButton, Submit, Button, SelectInput } from "components/UI"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import filterSubmitHandler from "utils/filterSubmitHandler"
import ChannelStore from "stores/ChannelStore/List"
import ClientSelect from "components/Common/ClientSelect"
import UserSelect from "components/Common/UserSelect"
import SubagentSelect from "components/Common/SubagentSelect"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import InsuranceKindSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsuranceKindSelect"
import TagSelect from "components/Common/TagSelect"
import HoldingSelect from "components/Common/HoldingSelect"
import classnames from "classnames"

const channelStore = ChannelStore.create()

const Filter = ({ store, values, handleSubmit }) => {
  const [isShowAdditional, setIsShowAdditional] = useState(false)
  const toggleAddtional = () => setIsShowAdditional(!isShowAdditional)
  useEffect(() => {
    channelStore.fetch({ order_by_ordered: "asc" })
  }, [])

  const channelOptions = (() => {
    if (values.by_channel) {
      const { id, name } = values.by_channel
      const options = channelStore.channelOptions
      if (id) {
        options.concat([{ label: name, value: id }])
      }
      return options
    }
    return channelStore.channelOptions
  })()

  const loadChannelOptions = (value) =>
    channelStore
      .fetch({ filter: { by_name: value, order_by_ordered: "asc" }, limit: 0 })
      .then(({ channelOptions }) => channelOptions)

  const [disabled, setDisabled] = useState(false)

  const onExportButtonHandler = () => {
    // console.log(values)
    setDisabled(true)
    store.export({ filter: values }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement("a")
      link.href = url
      link.setAttribute("download", "contract_installments.xlsx")
      document.body.appendChild(link)
      link.click()
      setDisabled(false)
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-3">
          <InsurerSelect name="by_insurer" />
        </div>
        <div className="col-3">
          <AsyncSelectInput
            name="by_channel.id"
            label="Канал продаж"
            options={channelOptions}
            isSearchable
            loadOptions={loadChannelOptions}
          />
        </div>
        <div className="col-3">
          <DateInput name="by_conclusion_on_from" label="Дата договора с" />
        </div>
        <div className="col-3">
          <DateInput name="by_conclusion_on_to" label="Дата договора по" />
        </div>
        <div className="col-3">
          <ClientSelect name="by_client.id" />
        </div>
        <div className="col-3">
          <TextInput name="by_number" label="№ договора" />
        </div>
        <div className="col-3">
          <DateInput name="by_payment_on_from" label="Период отчета с" />
        </div>
        <div className="col-3">
          <DateInput name="by_payment_on_to" label="Период отчета по" />
        </div>
        <div className="col-3">
          <UserSelect name="by_user.id" label="Исполнитель" />
        </div>
        <div className="col-3">
          <SubdivisionSelect name="by_subdivision.id" />
        </div>
        <div className="col-3">
          <InsuranceKindSelect name="by_insurance_kind" />
        </div>
        <div className="col-3">
          <SubagentSelect name="by_subagent.id" />
        </div>
        {isShowAdditional && (
          <Fragment>
            <div className="col-3">
              <TagSelect name="by_tag" label="Теги" />
            </div>
            <div className="col-3">
              <TextInput name="by_customer_inviter" label="Лицо, пригласившее Клиента" />
            </div>
            <div className="col-3">
              <SelectInput
                name="by_client_kind"
                label="Тип страхователя (ЮЛ/ФЛ)"
                isClearable
                options={[
                  {
                    label: "Физическое лицо",
                    value: "0"
                  },
                  {
                    label: "Юридическое лицо",
                    value: "1"
                  }
                ]}
              />
            </div>
            <div className="col-3">
              <SelectInput
                name="by_status"
                label="Статус"
                options={[
                  {
                    label: "Черновик",
                    value: "0"
                  },
                  {
                    label: "Выпущен",
                    value: "1"
                  }
                ]}
              />
            </div>
            <div className="col-3">
              <SelectInput
                name="by_contract_scans"
                label="Приложенные документы"
                isClearable
                options={[
                  {
                    label: "Не приложены",
                    value: "0"
                  },
                  {
                    label: "Приложены",
                    value: "1"
                  }
                ]}
              />
            </div>
            <div className="col-3">
              <HoldingSelect name="by_holding.id" />
            </div>
            <div className="col-3">
              <UserSelect name="by_maintenance_user.id" label="Сотрудник сопровождения" />
            </div>
          </Fragment>
        )}
      </div>
      <Button onClick={toggleAddtional} title="Дополнительные фильтры">
        <i className={classnames(["fas", { "fa-angle-up": isShowAdditional, "fa-angle-down": !isShowAdditional }])}></i>
      </Button>
      &nbsp;
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      &nbsp;
      <Button onClick={onExportButtonHandler} disabled={disabled}>
        {disabled && (
          <Fragment>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>&nbsp;
          </Fragment>
        )}
        Экспорт в .xlsx
      </Button>
      {store.meta.statistics.map((i) => (
        <Fragment>
          &nbsp;&nbsp;&nbsp;&nbsp;{i[0]}: <b>{i[1]}</b>
        </Fragment>
      ))}
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => filterSubmitHandler(values, formikBag)
  }),
  observer
)(Filter)
