import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import { ContractTask } from "./ContractTask"
import newCustomEnum from "types/newCustomEnum"
import customEnum from "types/customEnum"
import customDate from "types/customDate"
import format from "date-fns/format"

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Filter = types.model("Filter", {
  by_kind: types.maybeNull(types.string),
  by_status: types.maybeNull(types.string),
  by_subject: types.maybeNull(types.union(types.integer, types.string)),
  by_result: types.maybeNull(types.union(types.integer, types.string)),
  by_performer: types.maybeNull(customEnum),
  by_creator: types.maybeNull(customEnum),
  by_date_on_from: types.maybeNull(customDate),
  by_date_on_to: types.maybeNull(customDate),
  by_done_at_from: types.maybeNull(customDate),
  by_done_at_to: types.maybeNull(customDate),
  by_contract_id: types.maybeNull(types.integer),
  by_contract_client: types.maybeNull(customEnum),
  by_plaintiff_or_defendant: types.maybeNull(types.string),
  by_debtor_or_сreditor: types.maybeNull(types.string),
  by_contract_proceeding_subject: types.maybeNull(types.string),
  by_parent: types.maybeNull(types.string),
  by_insurance_kind: types.maybeNull(customEnum),
  by_maintenance_user: types.maybeNull(customEnum),
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const ContractTaskStore = types
  .model("ContractTask", {
    data: types.array(ContractTask, []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_date_on_from, by_date_on_to, by_done_at_from, by_done_at_to } = params.filter
        if (by_date_on_from) {
          params.filter = { ...params.filter, by_date_on_from: format(by_date_on_from, "yyyy-MM-dd") }
        }
        if (by_date_on_to) {
          params.filter = { ...params.filter, by_date_on_to: format(by_date_on_to, "yyyy-MM-dd") }
        }
        if (by_done_at_from) {
          params.filter = { ...params.filter, by_done_at_from: format(by_done_at_from, "yyyy-MM-dd") }
        }
        if (by_done_at_to) {
          params.filter = { ...params.filter, by_done_at_to: format(by_done_at_to, "yyyy-MM-dd") }
        }
      }

      return instance
        .get("/contract_tasks", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchCalendar(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_date_on_from, by_date_on_to, by_done_at_from, by_done_at_to } = params.filter
        if (by_date_on_from) {
          params.filter = { ...params.filter, by_date_on_from: format(by_date_on_from, "yyyy-MM-dd") }
        }
        if (by_date_on_to) {
          params.filter = { ...params.filter, by_date_on_to: format(by_date_on_to, "yyyy-MM-dd") }
        }
        if (by_done_at_from) {
          params.filter = { ...params.filter, by_done_at_from: format(by_done_at_from, "yyyy-MM-dd") }
        }
        if (by_done_at_to) {
          params.filter = { ...params.filter, by_done_at_to: format(by_done_at_to, "yyyy-MM-dd") }
        }
      }

      return instance
        .get("/contract_tasks/calendar", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/contract_tasks/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ContractTaskStore
