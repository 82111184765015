import React, { useContext, useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import SubdivisionStore from "stores/SubdivisionStore/List"
import AuthStoreContext from "contexts/AuthStoreContext"

const subdivisionStore = SubdivisionStore.create()

const SubdivisionSelect = ({ name = "subdivision", required = false, label = "Подразделение", setCurrentUserSubdivision = false, ...props }) => {
  const { values, setFieldValue } = useFormikContext()

  const subdivision = values[name]

  useEffect(() => {
    subdivisionStore.search({limit: 0})
  }, [])

  const getOptions = () => {
    const { subdivisionOptions } = subdivisionStore
    const { id: value, name: label } = getIn(values, name) || { id: null, name: null }
    if (value) {
      subdivisionOptions.concat([{ label, value }])
    }
    return subdivisionOptions
  }

  const subdivisionOptions = getOptions()

  const loadSubdivisionOptions = (value) =>
    subdivisionStore
      .search({ filter: { by_name: value }, limit: 0 })
      .then(({ subdivisionOptions }) => subdivisionOptions)

  const { data: currentUser } = useContext(AuthStoreContext)

  if (setCurrentUserSubdivision && subdivision.id === null)
    setFieldValue(name, { id: currentUser.subdivision.id, name: currentUser.subdivision.name })

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={`${name}.id`}
        label={label}
        options={subdivisionOptions}
        required={required}
        isSearchable
        loadOptions={loadSubdivisionOptions}
        {...props}
      />
    </div>
  )
}

export default observer(SubdivisionSelect)
