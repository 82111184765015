import React from "react"
import { Route, Switch } from "react-router-dom"
import List from "./containers/List"
import CheckPermissions from "components/CheckPermissions"

const LegalKvCosts = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("kv_costs")}>
    <Switch>
      <Route path="/legal_kv_costs" component={List} />
    </Switch>
  </CheckPermissions>
)

export default LegalKvCosts
