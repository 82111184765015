import React, { useEffect } from "react"
import { observer } from "mobx-react"
import { AsyncSelectInput } from "components/UI"
import { useFormikContext, getIn } from "formik"
import SubdivisionStore from "stores/SubdivisionStore/List"

const subdivisionStore = SubdivisionStore.create()

const SubdivisionSelect = ({
  name = "subdivision",
  required = false,
  label = "Подразделение",
  isDisabled = false,
  by_year,
  ...props
}) => {
  const { values } = useFormikContext()

  useEffect(() => {
    subdivisionStore.budgets({ filter: { by_year: by_year, order_by_name: "asc" }, limit: 0 })
  }, [])

  const getOptions = () => {
    const { subdivisionOptions } = subdivisionStore
    const { id: value, name: label } = getIn(values, name) || { id: null, name: null }
    if (value) {
      return subdivisionOptions.concat([{ label, value }])
    }
    return subdivisionOptions
  }

  const subdivisionOptions = getOptions()

  const loadSubdivisionOptions = (value) =>
    subdivisionStore
      .budgets({ filter: { by_name: value, by_year: by_year, order_by_name: "asc" }, limit: 0 })
      .then(({ subdivisionOptions }) => subdivisionOptions)

  return (
    <div className="form-group">
      <AsyncSelectInput
        name={name}
        label={label}
        options={subdivisionOptions}
        required={required}
        isSearchable
        isDisabled={isDisabled}
        loadOptions={loadSubdivisionOptions}
        {...props}
      />
    </div>
  )
}

export default observer(SubdivisionSelect)
