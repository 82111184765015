import React, { Fragment } from "react"
import { TextInput, RadioButtonGroup, RadioInput, Button, SelectInput, Submit, CurrencyInput } from "components/UI"
import compose from "utils/compose"
import { observer } from "mobx-react"
import { withFormik } from "formik"
import { withRouter } from "react-router"
import formSubmitHandler from "utils/formSubmitHandler"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const Form = ({ store, values, budgetStore, handleSubmit, ...props }) => {
  const { statuses, bills } = store.filtersOptions
  const salePlan = values.budget_income_items.reduce((a, b) => a + parseMoney(b.cost), 0)

  const copyJanuary = () => {
    values.budget_income_items.slice(1).forEach((_, index) => {
      props.setFieldValue(`budget_income_items.${index + 1}.cost`, values.budget_income_items[0].cost)
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <RadioButtonGroup label="Статус" name="status.value">
        {statuses.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <SelectInput name="bill.id" label="Статья бюджета" options={bills} />
      <TextInput name="comment" label="Комментарий" />
      <p>
        Итого Прочие доходы {budgetStore.data.year}: {formatMoney(salePlan)}
      </p>
      <Button onClick={copyJanuary}>Скопировать январь</Button>
      <br />
      <br />
      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: "4300px" }}>
          <thead style={{ background: "#e5efdc" }}>
            <tr>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 1КВ{budgetStore.data.year}
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 2КВ{budgetStore.data.year}
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 3КВ{budgetStore.data.year}
              </th>
              <th scope="col" colSpan="4" style={{ border: "1px solid white", textAlign: "center" }}>
                План 4КВ{budgetStore.data.year}
              </th>
            </tr>
            <tr>
              <th style={{ border: "1px solid white" }}>1КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ЯНВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ФЕВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>МАРТ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>2КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>АПР{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>МАЙ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ИЮНЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>3КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ИЮЛЬ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>АВГ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>СЕН{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>4КВ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ОКТ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>НОЯ{budgetStore.data.year.toString().slice(2, 4)}</th>
              <th style={{ border: "1px solid white" }}>ДЕК{budgetStore.data.year.toString().slice(2, 4)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {values.budget_income_items.map((item, itemIndex) => (
                <Fragment key={`item=${itemIndex}`}>
                  {itemIndex % 3 === 0 && (
                    <td style={{ verticalAlign: "middle", width: "200px" }}>
                      {formatMoney(
                        values.budget_income_items.slice(itemIndex, itemIndex + 3).reduce((a, b) => a + parseMoney(b.cost), 0)
                      )}
                    </td>
                  )}
                  <td>
                    <CurrencyInput name={`budget_income_items.${itemIndex}.cost`} bare style={{ width: "100%" }} />
                  </td>
                </Fragment>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <Submit>Сохранить</Submit>
    </form>
  )
}

export default compose(
  withRouter,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.data }),
    handleSubmit: (values, formikBag) => {
      const { id: budget_id } = formikBag.props.budgetStore.data
      formSubmitHandler({ ...values, budget_id: budget_id }, formikBag, `/budgets/${budget_id}/budget_incomes`)
    }
  }),
  observer
)(Form)
