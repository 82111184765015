import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import { Button, BreadcrumbItem, Card } from "components/UI"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/CompanySummary/components/Tabs"
import instance from "connection/instance"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const budgetStore = BudgetStore.create()

const FinPlan = (props) => {
  const { id, budget_id } = props.match.params
  const [showMonths, setShowMonts] = useState(true)
  const [showSubdivisions, setShowSubdivisions] = useState(true)
  const [data, setData] = useState({})

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
  }, [id, budget_id])

  useEffect(() => {
    instance.get(`/budgets/${budget_id}/budget_company_summaries/fin_results_plan`).then(({ data }) => {
      setData(data.data)
    })
  }, [])

  const year = budgetStore.data.year.toString().slice(2, 4)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Свод</BreadcrumbItem>
        <BreadcrumbItem active>Фин. рез. (План)</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />
      <div className="row">
        <div className="col-lg-12">
          <div class="form-group">
            <label for="ERAjuorWqK">Вид столбцов</label>
            <fieldset>
              <div class="form-check-inline form-check">
                <input
                  class="form-check-input"
                  id="show_months_false"
                  type="radio"
                  name="months"
                  value={false}
                  checked={showMonths === false}
                  onChange={() => setShowMonts(false)}
                />
                <label class="form-check-label" for="show_months_false">
                  Свернуть мес.
                </label>
              </div>
              <div class="form-check-inline form-check">
                <input
                  class="form-check-input"
                  id="show_months_true"
                  type="radio"
                  name="months"
                  value={true}
                  checked={showMonths === true}
                  onChange={() => setShowMonts(true)}
                />
                <label class="form-check-label" for="show_months_true">
                  Развернуть мес.
                </label>
              </div>
            </fieldset>
          </div>
          <div class="form-group">
            <label for="ERAjuorWqK">Вид строк</label>
            <fieldset>
              <div class="form-check-inline form-check">
                <input
                  class="form-check-input"
                  id="bEADGzWZgi"
                  type="radio"
                  name="budget.id"
                  value={false}
                  checked={showSubdivisions === false}
                  onChange={() => setShowSubdivisions(false)}
                />
                <label class="form-check-label" for="bEADGzWZgi">
                  Свернуть подразделения
                </label>
              </div>
              <div class="form-check-inline form-check">
                <input
                  class="form-check-input"
                  id="zQVzlyBoiV"
                  type="radio"
                  name="budget.id"
                  value={true}
                  checked={showSubdivisions === true}
                  onChange={() => setShowSubdivisions(true)}
                />
                <label class="form-check-label" for="zQVzlyBoiV">
                  Развернуть подразделения
                </label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>

      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: showMonths ? "4300px" : "1500px" }}>
          <thead style={{ background: "#e5efdc" }}>
            <th style={{ border: "1px solid white" }}>Статья бюджета</th>
            {showSubdivisions && <th style={{ border: "1px solid white" }}>Подр.</th>}
            <th style={{ border: "1px solid white" }}>Год</th>
            <th style={{ border: "1px solid white" }}>1КВ{year}</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>ЯНВ{year}</th>
                <th style={{ border: "1px solid white" }}>ФЕВ{year}</th>
                <th style={{ border: "1px solid white" }}>МАРТ{year}</th>
              </Fragment>
            )}
            <th style={{ border: "1px solid white" }}>2КВ{year}</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>АПР{year}</th>
                <th style={{ border: "1px solid white" }}>МАЙ{year}</th>
                <th style={{ border: "1px solid white" }}>ИЮНЬ{year}</th>
              </Fragment>
            )}
            <th style={{ border: "1px solid white" }}>3КВ{year}</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>ИЮЛЬ{year}</th>
                <th style={{ border: "1px solid white" }}>АВГ{year}</th>
                <th style={{ border: "1px solid white" }}>СЕН{year}</th>
              </Fragment>
            )}
            <th style={{ border: "1px solid white" }}>4КВ{year}</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>ОКТ{year}</th>
                <th style={{ border: "1px solid white" }}>НОЯ{year}</th>
                <th style={{ border: "1px solid white" }}>ДЕК{year}</th>
              </Fragment>
            )}
          </thead>
          <tbody>
            {Object.entries(data).map((i) => (
              <Fragment>
                <tr>
                  <td rowSpan={showSubdivisions && i[1][0]["subdivision_name"] !== null ? i[1].length + 2 : 2}>
                    {i[0]}
                  </td>
                </tr>
                <tr>
                  {showSubdivisions && i[1][0]["subdivision_name"] !== null ? (
                    <td>Итого</td>
                  ) : showSubdivisions ? (
                    <td></td>
                  ) : null}
                  <td>
                    {formatMoney(
                      i[1]
                        .map((subdivision) => subdivision.year)
                        .flat()
                        .reduce((a, b) => a + parseMoney(b), 0)
                    )}
                  </td>
                  {i[1][0].months.map((_, month) => (
                    <Fragment key={`item=${month}`}>
                      {month % 3 === 0 && (
                        <td style={{ verticalAlign: "top", width: "200px" }}>
                          {formatMoney(
                            i[1]
                              .map((subdivision) => subdivision.quarters[month / 3])
                              .flat()
                              .reduce((a, b) => a + parseMoney(b), 0)
                          )}
                        </td>
                      )}
                      {showMonths && (
                        <td>
                          {formatMoney(
                            i[1]
                              .map((subdivision) => subdivision.months[month])
                              .flat()
                              .reduce((a, b) => a + parseMoney(b), 0)
                          )}
                        </td>
                      )}
                    </Fragment>
                  ))}
                </tr>
                {showSubdivisions &&
                  i[1][0]["subdivision_name"] !== null &&
                  i[1].map((values) => (
                    <tr>
                      <td>{values.subdivision_name}</td>
                      <td>{formatMoney(values.year)}</td>
                      {values.months.map((value, month) => (
                        <Fragment key={`item=${month}`}>
                          {month % 3 === 0 && (
                            <td style={{ verticalAlign: "top", width: "200px" }}>
                              {values.quarters[month / 3] != null ? formatMoney(values.quarters[month / 3]) : ""}
                            </td>
                          )}
                          {showMonths && <td>{value != null ? formatMoney(value) : ""}</td>}
                        </Fragment>
                      ))}
                    </tr>
                  ))}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default observer(FinPlan)
