import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import toOptions from "utils/toOptions"
import newToOptions from "utils/newToOptions"
import customEnum from "types/customEnum"
import newCustomEnum from "types/newCustomEnum"
import { BudgetIncome } from "./BudgetIncome"
import { prepare } from "./utils"

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, []),
  bills: types.array(customEnum, [])
})

const Meta = types.model("Meta", {
  filters_options: types.maybeNull(FilterOptions)
})

const metaDefaultValue = {
  filters_options: {
    statuses: [],
    bills: []
  }
}

const initialState = {
  status: { value: 0 },
  bill: { id: null },
  comment: null,
  budget_income_items: [
    {
      month: 1,
      cost: "0.0"
    },
    {
      month: 2,
      cost: "0.0"
    },
    {
      month: 3,
      cost: "0.0"
    },
    {
      month: 4,
      cost: "0.0"
    },
    {
      month: 5,
      cost: "0.0"
    },
    {
      month: 6,
      cost: "0.0"
    },
    {
      month: 7,
      cost: "0.0"
    },
    {
      month: 8,
      cost: "0.0"
    },
    {
      month: 9,
      cost: "0.0"
    },
    {
      month: 10,
      cost: "0.0"
    },
    {
      month: 11,
      cost: "0.0"
    },
    {
      month: 12,
      cost: "0.0"
    }
  ]
}

const BudgetIncomeStore = types
  .model("BudgetIncomeStore", {
    data: types.optional(BudgetIncome, initialState),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get id() {
      return self.data.id
    },

    get filtersOptions() {
      const { statuses, bills } = self.meta.filters_options
      return {
        statuses: newToOptions(statuses),
        bills: toOptions(bills)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")
      const { id, budget_id, ...options } = params
      return instance
        .get(`/budgets/${budget_id}/budget_incomes/${id}`, { options })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta(params = {}) {
      self.setState("pending")
      const { budget_id, ...options } = params
      return instance
        .get(`/budgets/${budget_id}/budget_incomes/meta`, { options })
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    create(params = {}) {
      self.setState("pending")
      const { budget_id, ...data } = params
      return instance
        .post(`/budgets/${budget_id}/budget_incomes`, { data: prepare(data) })
        .then((response) => self.applyData(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    update(params = {}) {
      self.setState("pending")
      const { id, budget_id, ...data } = params
      return instance
        .put(`/budgets/${budget_id}/budget_incomes/${id}`, { data: prepare(data) })
        .then((response) => this.applyData(response))
        .then((response) => this.setState("done", response))
        .catch((error) => this.errorHandler(error))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, data)

      return self
    },

    applyData(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: data.data, meta: self.meta })

      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    },

    reset: () => {
      applySnapshot(self, {
        data: initialState,
        meta: self.meta
      })
    }
  }))

export default BudgetIncomeStore
