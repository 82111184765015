import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import {
  Card,
  CardTitle,
  DateInput,
  TextArea,
  TextInput,
  RadioButtonGroup,
  RadioInput,
  NumberInput,
  PolicyNumberInput
} from "components/UI"
import StandardPeriods from "./StandardPeriods"
import ClientSelect from "./ClientSelect"
import InsurerSelect from "./InsurerSelect"
import InsurerContactSelect from "./InsurerContactSelect"
import ChannelSelect from "./ChannelSelect"
import InsuranceKindSelect from "./InsuranceKindSelect"
import UserSelect from "./UserSelect"
import SubdivisionSelect from "./SubdivisionSelect"
import StartOnInput from "./StartOnInput"
import FinishOnInput from "./FinishOnInput"
import ParentSelect from "./ParentSelect"
import SubagentSelect from "./SubagentSelect"
import StandartCustomerInviters from "./StandartCustomerInviters"
import TagListSelect from "components/Common/TagListSelect"

const CommonTab = ({ store, values, ...props }) => {
  const { contractStatusesOptions, contractKindsOptions, useBsosOptions } = store.filtersOptions
  useEffect(() => {
    if (values.insurance_kind?.id == 10) {
      if (values.cargo_kind.id.toString() === "1") {
        // Генеральный
        props.setFieldValue("is_need_to_prolongation", true)
        props.setFieldValue("cancel_prolongation_cause", "")
      } else {
        props.setFieldValue("is_need_to_prolongation", false)
        props.setFieldValue("cancel_prolongation_cause", "Разовый договор")
      }
    }
    if (values.insurance_kind?.id == 14) {
      props.setFieldValue("kind.value", 1)
    }
    if ([42, 43, 44, 45, 46, 47, 48].includes(values.insurance_kind?.id) && values.insurer?.id === 43) {
      props.setFieldValue("is_need_to_prolongation", false)
      props.setFieldValue("cancel_prolongation_cause", "Разовый договор")
    }
  }, [values.insurance_kind?.id])

  useEffect(() => {
    if ([42, 43, 44, 45, 46, 47, 48].includes(values.insurance_kind?.id) && values.insurer?.id === 43) {
      props.setFieldValue("is_need_to_prolongation", false)
      props.setFieldValue("cancel_prolongation_cause", "Разовый договор")
    }
  }, [values.insurer?.id])

  const hasAvr = values.contract_installments.some((i) => i.avrs.length > 0)

  return (
    <Fragment>
      <br />
      <RadioButtonGroup name="status.value" label="Статус">
        {contractStatusesOptions.map(({ label, value }) => (
          <RadioInput key={`status_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <InsuranceKindSelect
        required
        isDisabled={
          (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
          values.has_additional ||
          hasAvr
        }
      />
      <InsurerSelect
        isDisabled={
          (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
          values.has_additional ||
          hasAvr
        }
        required={values.status.value.toString() === "1"}
      />
      <InsurerContactSelect
        isDisabled={values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1"}
      />
      <ChannelSelect
        required={values.status.value.toString() === "1"}
        isDisabled={
          (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
          values.has_additional ||
          hasAvr
        }
      />
      <ClientSelect
        required
        isDisabled={
          (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
          values.has_additional ||
          hasAvr
        }
      />

      <div className="row">
        <div className="col-6">
          <UserSelect required setCurrentUser />
        </div>
        <div className="col-6">
          <UserSelect name="maintenance_user" label="Сотрудник сопровождения" subdivisionName="maintenance_subdivision" />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <SubdivisionSelect required isDisabled={values.has_additional} setCurrentUserSubdivision />
        </div>
        <div className="col-6">
          <SubdivisionSelect name="maintenance_subdivision" label="Подразделение сопровождения" />
        </div>
      </div>

      <RadioButtonGroup
        name="customer_inviter_is_subagent.value"
        label="Лицо, пригласившее страхователя, является субагентом?"
        disabled={
          (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
          values.has_additional
        }
      >
        {useBsosOptions.map(({ label, value }) => (
          <RadioInput
            key={`customer_inviter_is_subagent_${value}`}
            label={label}
            id={value}
            onChange={(e) => {
              props.setFieldValue("customer_inviter_is_subagent.value", e.target.value)
              props.setFieldValue("customer_inviter", null)
            }}
          />
        ))}
      </RadioButtonGroup>
      <SubagentSelect
        isDisabled={
          (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
          values.has_additional
        }
      />

      {values.customer_inviter_is_subagent.value.toString() === "0" && (
        <Fragment>
          <TextInput
            name="customer_inviter"
            label="Лицо, пригласившее страхователя"
            required={values.status.value.toString() === "1"}
            disabled={
              (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
              values.customer_inviter_is_subagent.value.toString() === "1" ||
              values.has_additional
            }
          />
          <StandartCustomerInviters />
          <br />
          <br />
        </Fragment>
      )}

      <DateInput
        name="conclusion_on"
        label="Дата заключения"
        onChange={(value) => {
          props.setFieldValue("conclusion_on", value)
          props.setFieldValue("sp_accrual_on", value)
        }}
        required={values.status.value.toString() === "1"}
        disabled={
          (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
          values.has_additional ||
          hasAvr
        }
      />
      <NumberInput
        name="number"
        label="Номер договора"
        required={values.status.value.toString() === "1"}
        disabled={
          (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
          values.has_additional ||
          hasAvr
        }
      />
      {String(values.insurance_kind.id) === "14" && String(values.insurer.id) === "13" && (
        <PolicyNumberInput
          name="policy_number"
          label="Внутренний номер"
          required={values.status.value.toString() === "1"}
        />
      )}
      <RadioButtonGroup
        name="kind.value"
        label="Вид договора"
        disabled={
          (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
          values.has_additional
        }
      >
        {contractKindsOptions.map(({ label, value }) => (
          <RadioInput key={`kind_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      {/* <TextInput
        name="number"
        label="Номер договора"
        required={values.status.value.toString() === "1"}
        disabled={values.status.value.toString() === "1" || values.has_additional}
        onChange={(e) => {
          let { value } = e.target
          if (String(values.insurance_kind.id) === "14") {
            value = value.replace(/[^АВЕКМНОРСТУХ\d]/g, "")
          }

          props.setFieldValue("number", value)
        }}
      /> */}
      {(values.has_additional || values.versions.length > 0) && (
        <Fragment>
          <DateInput
            name="additional_agreement_on"
            label={`Дата заключения ${values.insurance_kind.id === 14 ? "аддендума" : "доп. соглашения"}`}
            onChange={(value) => {
              props.setFieldValue("additional_agreement_on", value)
              if (!values.additional_start_on) {
                props.setFieldValue("additional_start_on", value)
              }
            }}
            disabled={
              (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") || hasAvr
            }
          />
          <DateInput
            name="additional_start_on"
            label={`Дата начала ${values.insurance_kind.id === 14 ? "аддендума" : "доп. соглашения"}`}
            disabled={values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1"}
          />
          <NumberInput
            name="additional_agreement_number"
            label={`Номер ${values.insurance_kind.id === 14 ? "аддендума" : "доп. соглашения"}`}
            disabled={
              (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") || hasAvr
            }
          />
          <RadioButtonGroup
            name="additional_agreement_kind.value"
            label={`Вид ${values.insurance_kind.id === 14 ? "аддендума" : "доп. соглашения"}`}
          >
            {contractKindsOptions.map(({ label, value }) => (
              <RadioInput key={`kind_${value}`} label={label} id={value} />
            ))}
          </RadioButtonGroup>
          <TextArea
            name="additional_agreement_comment"
            label={`Существенные условия ${values.insurance_kind.id === 14 ? "аддендума" : "доп. соглашения"}`}
          />
        </Fragment>
      )}
      <RadioButtonGroup name="use_bso.value" label="Использовать БСО">
        {useBsosOptions.map(({ label, value }) => (
          <RadioInput key={`use_bso_${value}`} label={label} id={value} />
        ))}
      </RadioButtonGroup>
      <ParentSelect disabled={values.is_prolongation && values.parent && values.parent.id} client={values.client} />
      <Card>
        <CardTitle>Срок действия договора</CardTitle>
        <div className="form-group">
          <StartOnInput
            required={values.status.value.toString() === "1"}
            disabled={
              (values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1") ||
              values.has_additional
            }
          />
          <StandardPeriods
            disabled={values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1"}
          />
        </div>
        <FinishOnInput
          required={values.status.value.toString() === "1"}
          disabled={values.status.value.toString() === "1" && props.initialValues.status.value.toString() === "1"}
        />
        <TextInput
          required={values.status.value.toString() === "1"}
          name="duration"
          label="Срок действия договора"
          readOnly
        />
        <TextArea name="comment" label="Комментарий" />
        <RadioButtonGroup name="is_need_to_prolongation" label="Продлевать договор на следующий период?">
          {[
            { label: "Нет", value: false },
            { label: "Да", value: true }
          ].map(({ label, value }) => (
            <RadioInput key={`is_need_to_prolongation_${value}`} label={label} id={value} />
          ))}
        </RadioButtonGroup>
        {values.is_need_to_prolongation.toString() === "false" && (
          <TextInput name="cancel_prolongation_cause" label="Причина отказа от продления" />
        )}
      </Card>
      <TagListSelect name="tag_list" label="Теги" />
    </Fragment>
  )
}

export default observer(CommonTab)
