import React from "react"
import { Route, Switch } from "react-router-dom"
import Add from "./containers/Add"
import Edit from "./containers/Edit"
import List from "./containers/List"
import Show from "./containers/Show"
import Turnover from "./containers/Turnover"
import BudgetFots from "./containers/BudgetFots"
import BudgetNors from "./containers/BudgetNors"
import BudgetMotivations from "./containers/BudgetMotivations"
import SalePlan from "./containers/SalePlan"
import Coefficient from "./containers/Coefficient"
import Summary from "./containers/Summary"
import CompanySummary from "./containers/CompanySummary"
import UserSalePlans from "./containers/UserSalePlans"
import BudgetMotivationCoefficients from "./containers/BudgetMotivationCoefficients"
import BudgetIncomes from "./containers/BudgetIncomes"
import CheckPermissions from "components/CheckPermissions"

const Budgets = () => (
  <CheckPermissions condition={(privileges) => privileges.includes("budgets")}>
    <Switch>
      <Route path="/budgets/:id/turnover" component={Turnover} />
      <Route path="/budgets/:id/budget_fots" component={BudgetFots} />
      <Route path="/budgets/:id/budget_nors" component={BudgetNors} />
      <Route path="/budgets/:id/budget_motivations" component={BudgetMotivations} />
      <Route path="/budgets/:id/sale_plan" component={SalePlan} />
      <Route path="/budgets/:id/coefficient" component={Coefficient} />
      <Route path="/budgets/:id/summary" component={Summary} />
      <Route path="/budgets/:id/company_summary" component={CompanySummary} />
      <Route path="/budgets/:id/user_sale_plans" component={UserSalePlans} />
      <Route path="/budgets/:id/budget_motivation_coefficients" component={BudgetMotivationCoefficients} />
      <Route path="/budgets/:id/budget_incomes" component={BudgetIncomes} />
      <Route path="/budgets/:id/edit" component={Edit} />
      <Route path="/budgets/new" component={Add} />
      <Route path="/budgets/:id" component={Show} />
      <Route path="/budgets" component={List} />
    </Switch>
  </CheckPermissions>
)

export default Budgets
