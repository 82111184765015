import React, { Fragment, useState } from "react"
import compose from "utils/compose"
import { withRouter } from "react-router"
import { withFormik } from "formik"
import { observer } from "mobx-react"
import { Button, ResetButton, Submit, TextInput, DateInput } from "components/UI"
import filterSubmitHandler from "utils/filterSubmitHandler"
import classnames from "classnames"

import InsurerSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsurerSelect"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"

import InsuranceKindSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/InsuranceKindSelect"
import SubdivisionSelect from "components/Common/SubdivisionSelect"
import ClientSelect from "components/Common/ClientSelect"

const Filter = ({ store, handleSubmit }) => {
  const [isShowAdditional, setIsShowAdditional] = useState(false)
  const toggleAddtional = () => setIsShowAdditional(!isShowAdditional)
  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        {/* <div className="col-3">
          <InsurerSelect name="by_insurer" />
        </div> */}
        <div className="col-3">
          <ChannelSelect name="by_channel" />
        </div>
        <div className="col-3">
          <DateInput name="by_payment_on_start" label="Дата оплаты СП с" />
        </div>
        <div className="col-3">
          <DateInput name="by_payment_on_finish" label="Дата оплаты СП по" />
        </div>
        <div className="col-3">
          <DateInput name="by_avr_payment_on_start" label="Дата оплаты АВР с" />
        </div>
        <div className="col-3">
          <DateInput name="by_avr_payment_on_finish" label="Дата оплаты АВР по" />
        </div>
        <div className="col-3">
          <TextInput name="by_avr_number" label="№ АВР" />
        </div>
        {isShowAdditional && (
          <Fragment>
            <div className="col-3">
              <InsuranceKindSelect name="by_insurance_kind" />
            </div>
            <div className="col-3">
              <ClientSelect name="by_client.id" />
            </div>
            <div className="col-3">
              <TextInput name="by_number" label="№ договора" />
            </div>
            <div className="col-3">
              <SubdivisionSelect name="by_subdivision.id" />
            </div>
          </Fragment>
        )}
      </div>
      <Button onClick={toggleAddtional} title="Дополнительные фильтры">
        <i className={classnames(["fas", { "fa-angle-up": isShowAdditional, "fa-angle-down": !isShowAdditional }])}></i>
      </Button>
      &nbsp;
      <Submit>Найти</Submit>
      &nbsp;
      <ResetButton />
      {store.meta.statistics.map((i) => (
        <Fragment key={`statustic-${i}`}>
          &nbsp;&nbsp;&nbsp;&nbsp;{i[0]}: <b>{i[1]}</b>
        </Fragment>
      ))}
    </form>
  )
}

export default compose(
  withRouter,
  observer,
  withFormik({
    enableReinitialize: true,
    mapPropsToValues: ({ store }) => ({ ...store.meta.filter }),
    handleSubmit: (values, formikBag) => {
      formikBag.props.resetSelected()
      filterSubmitHandler(values, formikBag, {}, "fetchReportLegal")
    }
  })
)(Filter)
