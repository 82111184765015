import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect, useState } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import { Button, BreadcrumbItem, Card } from "components/UI"
import Tabsss from "pages/Budgets/components/Tabs"
import TabsBlock from "pages/Budgets/containers/CompanySummary/components/Tabs"
import instance from "connection/instance"
import parseMoney from "utils/parseMoney"
import formatMoney from "utils/formatMoney"

const budgetStore = BudgetStore.create()

const FinPlan = (props) => {
  const { id, budget_id } = props.match.params
  const [showMonths, setShowMonts] = useState(true)
  const [showSubdivisions, setShowSubdivisions] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
  }, [id, budget_id])

  useEffect(() => {
    instance.get(`/budgets/${budget_id}/budget_company_summaries/plan_fact`).then(({ data }) => {
      setData(data.data)
    })
  }, [])

  const year = budgetStore.data.year.toString().slice(2, 4)

  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>
          {budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : "Компания"} / {budgetStore.data.year}
        </BreadcrumbItem>
        <BreadcrumbItem active>Свод</BreadcrumbItem>
        <BreadcrumbItem active>Фин. рез. (План)</BreadcrumbItem>
      </Breadcrumbs>
      <Card>
        <Button url={`/budgets/${budget_id}/edit`}>Редактировать</Button>
      </Card>
      <Tabsss store={budgetStore} />
      <br />
      <TabsBlock store={budgetStore} />
      <br />

      <div style={{ overflowX: "scroll" }}>
        <table className="table table-bordered" style={{ width: showMonths ? "4300px" : "1500px" }}>
          <thead style={{ background: "#e5efdc" }}>
            <th style={{ border: "1px solid white" }}>Статья бюджета</th>
            {showSubdivisions && <th style={{ border: "1px solid white" }}></th>}
            <th style={{ border: "1px solid white" }}>ИТОГО{year}</th>
            <th style={{ border: "1px solid white" }}>3 мес.</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>ЯНВ{year}</th>
                <th style={{ border: "1px solid white" }}>ФЕВ{year}</th>
                <th style={{ border: "1px solid white" }}>МАРТ{year}</th>
              </Fragment>
            )}
            <th style={{ border: "1px solid white" }}>6 мес.</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>АПР{year}</th>
                <th style={{ border: "1px solid white" }}>МАЙ{year}</th>
                <th style={{ border: "1px solid white" }}>ИЮНЬ{year}</th>
              </Fragment>
            )}
            <th style={{ border: "1px solid white" }}>9 мес.</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>ИЮЛЬ{year}</th>
                <th style={{ border: "1px solid white" }}>АВГ{year}</th>
                <th style={{ border: "1px solid white" }}>СЕН{year}</th>
              </Fragment>
            )}
            <th style={{ border: "1px solid white" }}>12 мес.</th>
            {showMonths && (
              <Fragment>
                <th style={{ border: "1px solid white" }}>ОКТ{year}</th>
                <th style={{ border: "1px solid white" }}>НОЯ{year}</th>
                <th style={{ border: "1px solid white" }}>ДЕК{year}</th>
              </Fragment>
            )}
          </thead>
          <tbody>
            {data.map((i) => (
              <Fragment>
                <tr>
                  <td rowSpan={3}>{i.name}</td>
                  <td>План</td>
                  <td>{formatMoney(i.year.plan)}</td>
                  {new Array(12).fill(0).map((_, month) => (
                    <Fragment>
                      {month % 3 == 0 && <td>{formatMoney(i.quarters[month / 3].plan)}</td>}
                      {showMonths && <td>{formatMoney(i.months[month].plan)}</td>}
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  <td>Факт</td>
                  <td>{formatMoney(i.year.fact)}</td>
                  {new Array(12).fill(0).map((_, month) => (
                    <Fragment>
                      {month % 3 == 0 && <td>{formatMoney(i.quarters[month / 3].fact)}</td>}
                      {showMonths && <td>{formatMoney(i.months[month].fact)}</td>}
                    </Fragment>
                  ))}
                </tr>
                <tr>
                  <td>% исп.</td>
                  <td>{i.year.percentage}%</td>
                  {new Array(12).fill(0).map((_, month) => (
                    <Fragment>
                      {month % 3 == 0 && <td>{i.quarters[month / 3].percentage}%</td>}
                      {showMonths && <td>{i.months[month].percentage}%</td>}
                    </Fragment>
                  ))}
                </tr>
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  )
}

export default observer(FinPlan)
