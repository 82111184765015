import React, { Fragment, useContext, useState } from "react"
import { observer } from "mobx-react"
import { Card, CardTitle, CurrencyInput, DateInput } from "components/UI"
import formatMoney from "utils/formatMoney"
import parseMoney from "utils/parseMoney"
import formatDate from "utils/formatDate"
import ChannelSelect from "pages/Contracts/components/Form/InfoTab/CommonTab/ChannelSelect"
import AuthStoreContext from "contexts/AuthStoreContext"
import Accordion from "react-bootstrap/Accordion"
import BCard from "react-bootstrap/Card"

const FixKvTab = (form, props) => {
  const { data: currentUser } = useContext(AuthStoreContext)
  const disabled = ![3, 5, 10, 23, 49, 57].includes(currentUser.id) && ![2, 12].includes(currentUser.subdivision.id)

  const [activeKey, setActiveKey] = useState(null)
  return (
    <Fragment>
      <br />
      <Card>
        <CardTitle>Фиксированное КВ</CardTitle>
        <ChannelSelect
          name="fix_kv_channel"
          label="Канал продаж"
          disabled={form.values.status.value.toString() === "1" || disabled}
        />
        <CurrencyInput
          name="w"
          label="Сумма фиксированного КВ итого по договору"
          value={formatMoney(form.values.contract_installments.reduce((a, b) => a + parseMoney(b.fix_kv_cost), 0))}
          disabled
        />
      </Card>
      <Accordion defaultActiveKey="0" activeKey={activeKey}>
        {form.values.contract_installments
          .map((contract_installment, index) => (
            <BCard>
              <Accordion.Toggle
                style={{
                  background:
                    contract_installment.fix_kv_cost !== null && contract_installment.fix_kv_cost !== "0.00"
                      ? "#c3e6cb"
                      : "#f5c6cb"
                }}
                as={BCard.Header}
                eventKey={index.toString()}
                onClick={(e) => (activeKey === index.toString() ? setActiveKey(null) : setActiveKey(index.toString()))}
              >
                <strong>Платеж №{index + 1}</strong> | <strong>Дата:</strong>{" "}
                {formatDate(contract_installment.sp_payment_on)} | <strong>Фикс.КВ</strong>{" "}
                {formatMoney(contract_installment.fix_kv_cost)}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={index.toString()}>
                <div style={{ padding: "1.25rem" }}>
                  <div key={`contract_installments.${index}`}>
                    <Fragment>
                      <h5>Платеж № {index + 1}</h5>
                      <DateInput
                        name={`contract_installments.${index}.sp_payment_on`}
                        label="Дата очередного платежа СП"
                        disabled
                      />
                      <CurrencyInput
                        name={`contract_installments.${index}.sp_payment_cost`}
                        label="Сумма очередного платежа"
                        disabled
                      />
                      <CurrencyInput
                        name={`contract_installments.${index}.fix_kv_cost`}
                        label="Сумма фиксированного КВ по этому платежу"
                        disabled={
                          form.values.status.value.toString() === "1" ||
                          disabled ||
                          contract_installment.avrs.length > 0
                        }
                      />
                    </Fragment>
                  </div>
                </div>
              </Accordion.Collapse>
            </BCard>
          ))
          .reverse()}
      </Accordion>
      <br />
    </Fragment>
  )
}

export default observer(FixKvTab)
