import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import customDate from "types/customDate"
import customEnum from "types/customEnum"
import format from "date-fns/format"

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Filter = types.model("Filter", {
  by_insurer: types.maybeNull(customEnum),
  by_channel: types.maybeNull(customEnum),
  by_payment_on_start: types.maybeNull(customDate),
  by_payment_on_finish: types.maybeNull(customDate),
  by_avr_status: types.maybeNull(types.integer),
  by_contract_sending_status: types.maybeNull(types.integer),
  by_sending_deadline_avr: types.maybeNull(types.integer),
  by_payment_deadline_avr: types.maybeNull(types.integer),
  by_insurance_kind: types.maybeNull(customEnum),
  by_client: types.maybeNull(customEnum),
  by_number: types.maybeNull(types.union(types.integer, types.string)),
  by_subdivision: types.maybeNull(customEnum)
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number),
  statistics: types.frozen()
})

const metaDefaultValue = {
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  },
  statistics: []
}

const DebitStore = types
  .model("Debit", {
    data: types.array(types.frozen(), []),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get debitOptions() {
      return self.data.map(({ id, name }) => {
        return {
          label: name,
          value: id
        }
      })
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_payment_on_start, by_payment_on_finish } = params.filter
        if (by_payment_on_start) {
          params.filter = { ...params.filter, by_payment_on_start: format(by_payment_on_start, "yyyy-MM-dd") }
        }
        if (by_payment_on_finish) {
          params.filter = { ...params.filter, by_payment_on_finish: format(by_payment_on_finish, "yyyy-MM-dd") }
        }
      }

      return instance
        .get("/debits", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchLegal(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_payment_on_start, by_payment_on_finish } = params.filter
        if (by_payment_on_start) {
          params.filter = { ...params.filter, by_payment_on_start: format(by_payment_on_start, "yyyy-MM-dd") }
        }
        if (by_payment_on_finish) {
          params.filter = { ...params.filter, by_payment_on_finish: format(by_payment_on_finish, "yyyy-MM-dd") }
        }
      }

      return instance
        .get("/debits/legal", { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    export(params = {}) {
      self.setState("pending")

      if (params.filter) {
        const { by_payment_on_start, by_payment_on_finish } = params.filter
        if (by_payment_on_start) {
          params.filter = { ...params.filter, by_payment_on_start: format(by_payment_on_start, "yyyy-MM-dd") }
        }
        if (by_payment_on_finish) {
          params.filter = { ...params.filter, by_payment_on_finish: format(by_payment_on_finish, "yyyy-MM-dd") }
        }
      }

      return instance.get("/debits/export_xlsx", {
        params,
        responseType: "arraybuffer",
        headers: {
          Accept: "application/vnd.ms-excel"
        }
      })
    },

    destroy(id) {
      self.setState("pending")
      return instance.delete(`/debits/${id}`)
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default DebitStore
