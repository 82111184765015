import Breadcrumbs from "pages/Budgets/components/Breadcrumbs"
import React, { Fragment, useEffect } from "react"
import { observer } from "mobx-react"
import BudgetStore from "stores/BudgetStore/One"
import BudgetIncomesStore from "stores/BudgetIncomeStore/One"
import Form from "pages/Budgets/containers/BudgetIncomes/components/Form"
import { BreadcrumbItem } from "components/UI"
// import BudgetFormContext from "contexts/BudgetFormContext"

const budgetStore = BudgetStore.create()
const budgetIncomeStore = BudgetIncomesStore.create()

const Show = (props) => {
  const { id, budget_id } = props.match.params
  useEffect(() => {
    budgetStore.fetch({ id: budget_id })
    budgetIncomeStore.fetch({ id, budget_id })
  }, [id, budget_id])
  console.log(budgetIncomeStore.isFetched)
  return (
    <Fragment>
      <Breadcrumbs>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}`}>{budgetStore.data.kind.value === 0 ? budgetStore.data.subdivision.name : 'Компания'} / {budgetStore.data.year}</BreadcrumbItem>
        <BreadcrumbItem url={`/budgets/${budgetStore.id}/budget_incomes`}>Прочие доходы</BreadcrumbItem>
        <BreadcrumbItem active>{budgetIncomeStore.id}</BreadcrumbItem>
      </Breadcrumbs>
      <br />
      <div className="row">
        <div className="col-12">
          <table className="table">
            <tbody>
              <tr>
                <td style={{ width: "50%" }}>Статус</td>
                <td style={{ width: "50%" }}>{budgetIncomeStore.data.status.label}</td>
              </tr>
              <tr>
                <td style={{ width: "50%" }}>Комментарий</td>
                <td style={{ width: "50%" }}>{budgetIncomeStore.data.comment}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  )
}

export default observer(Show)
