const prepare = (params = {}) => {
  const {
    channel: { id: channel_id },
    fix_kv_channel: { id: fix_kv_channel_id },
    client: { id: client_id },
    insurance_kind: { id: insurance_kind_id },
    insurance_product: { id: insurance_product_id },
    insurer: { id: insurer_id },
    insurer_contact: { id: insurer_contact_id },
    user: { id: user_id },
    subdivision: { id: subdivision_id },
    maintenance_user: { id: maintenance_user_id },
    maintenance_subdivision: { id: maintenance_subdivision_id },
    subagent: { id: subagent_id },
    parent: { id: parent_id },
    child: { id: child_id },
    use_bso,
    status,
    kind,
    additional_agreement_kind,
    customer_inviter_is_subagent,
    sp_paid_inflector: { id: sp_paid_inflector_id },
    sp_sk_retention: { id: sp_sk_retention_id },
    cargo_kind: { id: cargo_kind_id },
    cargo_procedure_for_submitting_declaration: { id: cargo_procedure_for_submitting_declaration_id },
    cargo_procedure_for_certificate_issue: { id: cargo_procedure_for_certificate_issue_id },
    cargo_procedure_for_sp_payment: { id: cargo_procedure_for_sp_payment_id },
    cargo_prefabricated: { id: cargo_prefabricated_id },
    cargo_combined: { id: cargo_combined_id },
    contract_installments,
    contract_returnments,
    contract_properties,
    bsos,
    kvs,
    avrs,
    contract_returnment_avrs,
    documents,
    scans,
    contract_auto,
    contract_dms,
    contract_accidents,
    contract_osgop,
    contract_go_estate,
    contract_proceeding,
    contract_bankruptcy,
    versions,
    tracking,
    cargos,
    borderos,
    parcel_borderos,
    shipments,
    couriers,
    contract_scans,
    was_released,
    ...other
  } = params
  return {
    ...other,
    channel_id,
    fix_kv_channel_id,
    client_id,
    insurance_kind_id,
    insurance_product_id,
    insurer_id,
    insurer_contact_id,
    subagent_id,
    parent_id,
    user_id,
    subdivision_id,
    maintenance_user_id,
    maintenance_subdivision_id,
    sp_paid_inflector_id,
    sp_sk_retention_id,
    cargo_kind_id,
    cargo_procedure_for_submitting_declaration_id,
    cargo_procedure_for_certificate_issue_id,
    cargo_procedure_for_sp_payment_id,
    cargo_prefabricated_id,
    cargo_combined_id,
    bso_ids: bsos
      .map((i) => {
        if (i._destroy === true) {
          return { ...i, is_current: false }
        } else {
          return { ...i, is_current: true }
        }
      })
      .map((i) => {
        return { id: i.id, is_current: i.is_current }
      }),
    use_bso: use_bso.value,
    status: parseInt(status.value),
    kind: parseInt(kind.value),
    additional_agreement_kind: parseInt(additional_agreement_kind.value),
    customer_inviter_is_subagent: customer_inviter_is_subagent.value,
    contract_installments: contract_installments.map((i) => {
      const {
        contract_payment_status,
        sp_payment_kind: { value: sp_payment_kind },
        sp_sinergy_payment_form: { value: sp_sinergy_payment_form },
        sp_sk_payment_form: { value: sp_sk_payment_form },
        avrs,
        ...other
      } = i
      return {
        ...other,
        sp_payment_kind,
        sp_sinergy_payment_form,
        sp_sk_payment_form
      }
    }),
    contract_returnments: contract_returnments.map((i) => {
      const {
        sp_payment_kind: { value: sp_payment_kind },
        // sp_sinergy_payment_form: { value: sp_sinergy_payment_form },
        sp_sk_payment_form: { value: sp_sk_payment_form },
        avrs,
        returnment,
        ...other
      } = i
      return {
        ...other,
        sp_payment_kind,
        // sp_sinergy_payment_form,
        sp_sk_payment_form
      }
    }),
    contract_properties: contract_properties.map((i) => {
      const {
        kind: { id: kind_id },
        inflector: { id: inflector_id },
        ...other
      } = i
      return {
        ...other,
        kind_id,
        inflector_id
      }
    }),
    cargos: cargos.map((i) => {
      const {
        currency: { value: currency },
        ...other
      } = i
      return {
        ...other,
        currency: parseInt(currency)
      }
    }),
    kvs: kvs.map((i) => {
      const {
        channel: { id: channel_id },
        ...other
      } = i
      return {
        ...other,
        channel_id
      }
    }),
    // documents: documents.map((i) => {
    //   const {
    //     document_kind,
    //     kind: { value: kind },
    //     submission: { value: submission },
    //     submission_status: { value: submission_status },
    //     ...other
    //   } = i
    //   return {
    //     ...other,
    //     document_kind_id: document_kind?.id,
    //     kind,
    //     submission,
    //     submission_status
    //   }
    // }),
    // scans: scans.map((i) => {
    //   const {
    //     document_kind: { id: document_kind_id },
    //     kind: { value: kind },
    //     ...other
    //   } = i
    //   return {
    //     ...other,
    //     document_kind_id,
    //     kind
    //   }
    // }),
    contract_dms:
      insurance_kind_id == 7
        ? {
            // id: contract_dms.id,
            number_of_insured: parseInt(contract_dms.number_of_insured),
            current_number_of_insured: parseInt(contract_dms.current_number_of_insured),
            date_on: contract_dms.date_on,
            comment: contract_dms.comment,
            kind: parseInt(contract_dms.kind?.value)
          }
        : {},
    contract_auto: [9, 13, 14, 15].includes(insurance_kind_id)
      ? {
          id: contract_auto.id,
          car_brand_id: parseInt(contract_auto.car_brand.value),
          car_model_id: parseInt(contract_auto.car_model.value),
          risk: parseInt(contract_auto.risk.value),
          number_kind: parseInt(contract_auto.number_kind.value),
          number: contract_auto.number
        }
      : {},
    contract_accidents: contract_accidents.map((i) => {
      const {
        risk: { value: risk },
        ...other
      } = i
      return {
        ...other,
        risk: parseInt(risk)
      }
    }),
    contract_osgop:
      insurance_kind_id == 36
        ? {
            ...contract_osgop,
            kind: parseInt(contract_osgop.kind.value)
          }
        : {},
    contract_go_estate: [16, 17].includes(insurance_kind_id)
      ? {
          ...contract_go_estate,
          object: contract_go_estate.object ? parseInt(contract_go_estate.object.value) : null
        }
      : {},
    contract_proceeding: [43].includes(insurance_kind_id)
      ? (() => {
          const { plaintiff, defendant, court, contract_tasks, ...other } = contract_proceeding
          return {
            ...other,
            plaintiff_id: plaintiff ? plaintiff.id : null,
            defendant_id: defendant ? defendant.id : null,
            court_id: court ? court.id : null,
            status: contract_proceeding.status ? contract_proceeding.status.value : null,
            side: contract_proceeding.side ? contract_proceeding.side.value : null,
            result: contract_proceeding.result ? contract_proceeding.result.value : null
          }
        })()
      : {},
    contract_bankruptcy: [44].includes(insurance_kind_id)
      ? (() => {
          const { debtor, сreditor, court, contract_tasks, ...other } = contract_bankruptcy
          return {
            ...other,
            debtor_id: debtor ? debtor.id : null,
            сreditor_id: сreditor ? сreditor.id : null,
            court_id: court ? court.id : null,
            status: contract_bankruptcy.status ? contract_bankruptcy.status.value : null,
            side: contract_bankruptcy.side ? contract_bankruptcy.side.value : null,
            result: contract_bankruptcy.result ? contract_bankruptcy.result.value : null
          }
        })()
      : {}
  }
}

export { prepare }
