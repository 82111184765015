import { types, applySnapshot } from "mobx-state-tree"
import instance from "connection/instance"
import newCustomEnum from "types/newCustomEnum"
import toOptions from "utils/newToOptions"
import customDate from "types/customDate"
import customEnum from "types/customEnum"
import format from "date-fns/format"
// import { ContractDocument } from "./ContractDocument"

const FilterOptions = types.model("FilterOptions", {
  statuses: types.array(newCustomEnum, []),
  kinds: types.array(newCustomEnum, []),
  submissions: types.array(newCustomEnum, []),
  submission_statuses: types.array(newCustomEnum, [])
})

const Paginate = types.model("Paginate", {
  page: types.integer,
  pages: types.integer,
  count: types.integer
})

const Filter = types.model("Filter", {
  by_insurer: types.maybeNull(customEnum),
  by_client: types.maybeNull(types.integer),
  by_insurance_kind: types.maybeNull(types.integer),
  by_status: types.maybeNull(types.integer),
  by_subdivision: types.maybeNull(customEnum),
  by_conclusion_on_from: types.maybeNull(customDate),
  by_conclusion_on_to: types.maybeNull(customDate),
  by_number: types.maybeNull(types.union(types.integer, types.string)),
  by_kind: types.maybeNull(types.integer),
  by_submission: types.maybeNull(types.integer),
  by_submission_holder: types.maybeNull(types.union(types.integer, types.string)),
  by_document_kind: types.maybeNull(customEnum),
  by_maintenance_user: types.maybeNull(customEnum),
})

const Meta = types.model("Meta", {
  filter: types.maybeNull(Filter),
  filters_options: types.maybeNull(FilterOptions),
  pagination: types.maybeNull(Paginate),
  search_id: types.maybeNull(types.number)
})

const metaDefaultValue = {
  filters_options: {},
  pagination: {
    page: 1,
    pages: 1,
    count: 1
  }
}

const ContractInstallmentStore = types
  .model("ContractInstallment", {
    data: types.array(types.frozen()),
    meta: types.optional(Meta, metaDefaultValue),
    state: types.maybeNull(types.enumeration(["pending", "done", "error"]))
  })
  .views((self) => ({
    get isFetched() {
      return self.state === "done"
    },

    get isPending() {
      return self.state === "pending"
    },

    get isError() {
      return self.state === "error"
    },

    get filtersOptions() {
      const { statuses, kinds, submissions } = self.meta.filters_options
      return {
        statuses: toOptions(statuses),
        kinds: toOptions(kinds),
        submissions: toOptions(submissions)
      }
    }
  }))
  .actions((self) => ({
    fetch(params = {}) {
      self.setState("pending")

      const { contract_id, ...options } = params

      if (params.filter) {
        const { by_conclusion_on_from, by_conclusion_on_to } = params.filter
        if (by_conclusion_on_from) {
          params.filter = { ...params.filter, by_conclusion_on_from: format(by_conclusion_on_from, "yyyy-MM-dd") }
        }
        if (by_conclusion_on_to) {
          params.filter = { ...params.filter, by_conclusion_on_to: format(by_conclusion_on_to, "yyyy-MM-dd") }
        }
      }

      return instance
        .get(`/reports/contract_documents`, { params })
        .then((response) => self.resetStore(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    fetchMeta() {
      self.setState("pending")
      return instance
        .get(`/contract_documents/meta`)
        .then((response) => self.applyMeta(response))
        .then((response) => self.setState("done"))
        .catch((error) => self.errorHandler(error))
    },

    destroy(params) {
      const { id, contract_id } = params
      self.setState("pending")
      return instance.delete(`/contracts/${contract_id}/contract_documents/${id}`)
      // .then(response => dispatch(destroyUserSuccess(response)))
      // .catch(error => dispatch(destroyUserFailure(error)))
    },

    setState(state) {
      self.state = state
      return self
    },

    resetStore(response) {
      const { status, data } = response
      if (status === 200) applySnapshot(self, data)
      return self
    },

    applyMeta(response) {
      const { status, data } = response

      if (status === 200) applySnapshot(self, { data: self.data, meta: data.meta })

      return self
    },

    errorHandler(error) {
      self.setState("error")
      return Promise.reject(error)
    }
  }))

export default ContractInstallmentStore
